import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import NotFound from "./NotFound";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
