import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />

        <div className={"headline"}>
          <span>4</span>
          <span className={"dash"}>-</span>

          <span>0</span>
          <span className={"dash"}>-</span>
          <span>4</span>
        </div>
        <div className={"tagline"}>Page Not Found</div>
      </header>
    </div>
  );
}

export default App;
